;(function(root,factory){'use strict';if(typeof module==='object'&&typeof module.exports==='object'){factory(require('jquery'),root);}
if(typeof define==="function"){if(define.cmd){define(function(require,exports,module){var $=require("jquery");factory($,root);});}else{define(["jquery"],function($){factory($,root);});}}else{factory(root.jQuery,root);}}
(typeof window!=="undefined"?window:this,function($,root,undefined){'use strict';if(!$){$=root.jQuery||null;}
if(!$){throw new TypeError("必须引入jquery库方可正常使用！");}
function mModal(options){options=options||{};this.options=$.extend(true,{},mModal.defaultOptions,options);}
mModal.defaultOptions={title:"提示",width:"25%",top:"15vh",content:"正文内容什么",cancelText:"取 消",confirmText:"确 定",showCancelButton:true,showConfirmButton:true,showClose:true,modal:true,customClass:"",confirm:null,cancel:null,}
mModal.prototype={constructor:mModal,initElement:function(){var mWrapper=document.createElement("div");mWrapper.className="m-modal__wrapper";var mContainer=document.createElement("div");mContainer.className="m-modal__container";mWrapper.appendChild(mContainer);var mHeader=document.createElement("div");mHeader.className="m-modal__header";var mTitle=document.createElement("span");mTitle.className="m-modal__title";mHeader.appendChild(mTitle);var mHeaderbtn=document.createElement("button");mHeaderbtn.className="m-modal__headerbtn";var mClose=document.createElement("i");mClose.className="m-modal__close iconfont icon-close";mHeaderbtn.appendChild(mClose);mHeader.appendChild(mHeaderbtn);var mBody=document.createElement("div");mBody.className="m-modal__body";var mBodySpan=document.createElement("div");mBody.appendChild(mBodySpan);var mFooter=document.createElement("div");mFooter.className="m-modal__footer";var mButtonLeft=document.createElement("button");mButtonLeft.className="m-modal-button m-modal--primary";var mButtonRight=document.createElement("button");mButtonRight.className="m-modal-button m-modal--default";var mBtnSpanL=document.createElement("span");var mBtnSpanR=document.createElement("span");mFooter.appendChild(mButtonLeft).appendChild(mBtnSpanL);mFooter.appendChild(mButtonRight).appendChild(mBtnSpanR);mContainer.appendChild(mHeader);mContainer.appendChild(mBody);mContainer.appendChild(mFooter);document.body.appendChild(mWrapper);this.mWrapper=mWrapper;this.mContainer=mContainer;this.mHeader=mHeader;this.mHeaderbtn=mHeaderbtn;this.mBody=mBody;this.mFooter=mFooter;this.mButtonLeft=mButtonLeft;this.mButtonRight=mButtonRight;},renderDom:function(){var options=this.options;this.initElement();if(options.width){document.querySelector(".m-modal__container").style.width=options.width;}
if(options.title){document.querySelector(".m-modal__title").innerHTML=options.title;}
if(options.top){document.querySelector(".m-modal__container").style.marginTop=options.top;}
if(options.content){document.querySelector(".m-modal__body div").innerHTML=options.content;}
if(options.showConfirmButton){if(options.confirmText){document.querySelector(".m-modal__footer button:first-child span").innerHTML=options.confirmText;}}else{this.mFooter.removeChild(this.mButtonLeft);}
if(options.showCancelButton){if(options.cancelText){document.querySelector(".m-modal__footer button:last-child span").innerHTML=options.cancelText;}}else{this.mFooter.removeChild(this.mButtonRight);}
if(!options.showClose){this.mHeader.removeChild(this.mHeaderbtn);}
if(!options.modal){document.querySelector(".m-modal__wrapper").style.background="rgba(0, 0, 0, 0)";}
if(options.customClass){document.querySelector(".m-modal__container").classList.add(options.customClass);}
if(options.showConfirmButton&&options.confirm){this.mButtonLeft.onclick=function(){options.confirm();}}
if(options.showCancelButton){this.mButtonRight.onclick=function(){if(options.cancel){options.cancel();mModal.prototype.close();}else{mModal.prototype.close();}}}
if(options.showClose){this.mHeaderbtn.onclick=function(){mModal.prototype.close();}}},show:function(){document.querySelector(".m-modal__wrapper").style.display="block";},close:function(){document.querySelector(".m-modal__wrapper").style.display="none";var timer=null;timer=setTimeout(function(){clearTimeout(timer);mModal.prototype.destroy();},200);},destroy:function(){var mWrapper=document.querySelector(".m-modal__wrapper");var parentWrapper=mWrapper.parentNode;parentWrapper.removeChild(mWrapper);}}
window.mModal=mModal}));